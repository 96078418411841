import React, { useState, useEffect } from 'react';
import Topbar from '../../superadmin/Layouts/Topbar';
import '../../superadmin/styles/pages/dashboard.css';
import { Typography, Grid } from '@mui/material';
import admins_icon from '../../superadmin/assets/icons/admins-icon.svg';
import totalschools_icon from '../../superadmin/assets/icons/dashboard-school-icon.svg';
import students_icon from '../../superadmin/assets/icons/students-icon.svg';
import addadmin_icon from '../../superadmin/assets/icons/add-admin-icon.svg';
import instance from '../../utils/Api';
import { useNavigate } from "react-router-dom";


const Dashboard = () => {
    const navigate = useNavigate();
    const [superadmins, setsuperAdmins] = useState({});


    // Fetch admins 
    const fetchsuperAdmins = async () => {
        try {
            const response = await instance.get('Dashboard/superadminDashboard');
            setsuperAdmins(response.data);                  
        } catch (error) {
            console.error('Error fetching admins:', error.message);
        }
    };


    useEffect(() => {
        fetchsuperAdmins();
    }, []);

    return (
        <React.Fragment>
            <Topbar />
            <section className='main-1'>
                <div className='dashboard-content-text'>
                    <Typography variant='h2'>Welcome to your dashboard, Mr.Super Admin</Typography>
                    <Typography variant='h5'>Anganwadi@vibho.com</Typography>
                </div>
                <section className='dashboard-count'>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box' onClick={(e)=> navigate('/superadmin/admins')}>
                                <img src={admins_icon} alt='admins_icon' />
                                <Typography>Total Admins</Typography>
                                <Typography variant='h6'>{superadmins?.total_admins}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box' onClick={(e)=> navigate('/superadmin/schoollist')}>
                                <img src={totalschools_icon} alt='school_icon' />
                                <Typography>Total Schools</Typography>
                                <Typography variant='h6'>{superadmins?.total_schools}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <div className='dashboard-box-schools' >
                                <img src={students_icon} alt='students_icon' />
                                <Typography>Total Students</Typography>
                                <Typography variant='h6'>{superadmins?.total_students}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </section>
                <section className='admin-section'>
                    <div className='admin-conntent'>
                        <ul>
                            <li><img src={addadmin_icon} alt='addadmin_icon' /></li>
                            <li>
                                <Typography variant='h6'>Add Admin</Typography>
                                <Typography>Create rich course content and coaching products for your students.
                                    When you give them a pricing plan, they’ll appear on your site!</Typography>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
        </React.Fragment>
    )
}

export default Dashboard