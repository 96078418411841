import React, { useState, useEffect } from 'react';
import Topbar from '../../admin/layouts/Topbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, InputAdornment, IconButton, Menu, Typography,
    Button,
} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from '@mui/material/CircularProgress';
import instance from '../../utils/Api';
import { useGlobalContext } from '../../Contex'; // Adjust the path
import Addstudent from '../components/Add_student';


const Student = () => {
    const { openDialog, setOpenDialog } = useGlobalContext();
    const { anchorEl, setAnchorEl } = useGlobalContext();   //filter
    const { loading, setLoading } = useGlobalContext();  // State to handle loading
    const { searchTerm, setSearchTerm } = useGlobalContext(); //search state
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const [page, setPage] = useState(0);
    const rowsPerPage = 10; // Fixed rows per page
    const startIndex = (page) * rowsPerPage;  // Calculate the start and end index for pagination
    const [searchParams] = useSearchParams();
    const schoolId = searchParams.get('schoolId');
    const schoolname = searchParams.get('schoolname');
    const [students, setStudents] = useState([]);
    const [addstudents, setAddstudents] = useState(false);

    //   //filter function open menu
    //   const handleClick1 = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // //filter function close menu
    // const handleClose1 = () => {
    //     setAnchorEl(null);
    // };

    
  // Handle search
  const filteredstudents = students.filter((student) => {
    const searchTermLower = (searchTerm).toLowerCase(); // Ensure searchTerm is always a string
    return (
        student?.email?.toLowerCase().includes(searchTermLower)||
        student?.name?.toLowerCase().includes(searchTermLower)||
        student?.mother_name?.toLowerCase().includes(searchTermLower)||
        student?.Gender?.toLowerCase().includes(searchTermLower) || 
        // student?.Gender?.toUpperCase().includes(searchTermLower)||
        student?.mobile_no?.toString().includes(searchTermLower)||
        // student?.age?.toString().includes(searchTermLower)||
        student?.date_of_birth?.toLowerCase().includes(searchTermLower)||
        student?.age_limit?.toString().includes(searchTermLower)

    );
  });

    //handle change
    const handleChangePage = (event, newPage) => {
        setPage(newPage); // Adjust to be 1-based
    };

    // Data slicing based on current page and rowsPerPage
    const paginatedStudents = filteredstudents.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    //fetch students
    const fetchStudents = async () => {
        try {
            const response = await instance.get(`Students/StudentsList?school_id=${schoolId}`);
            setStudents(response.data.data);
        } catch (error) {
            console.error('Error fetching Schools:', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStudents();
        setAddstudents(false) // Reset after fetching
        setLoading(true);
    }, [addstudents]);

    // Function to handle opening the popup for adding new admin
    const handleAddClick = () => {
        // setSelectedStudent(null);  // Clear selected admin (add new mode)
        setOpenDialog(true);  // Open the popup
    };
    
    return (
        <React.Fragment>
            <Topbar />
            {loading ? (
                <div className="loading">
                    <CircularProgress />
                </div>
            ) : (<section className='main'>
                <div className='employees-text inventory-backtn'>
                    <div>
                        <Typography variant='h5'>Student List</Typography>
                        <Typography variant='h6' >School Name: <span>{schoolname}</span></Typography>
                    </div>
                    <div>
                        <Button style={{marginBottom:10, marginLeft:20}} onClick={() => navigate('/admin/schoollist')}><KeyboardBackspaceIcon />Back</Button>
                        {/* <Button variant="outlined"  onClick={() => handleAddClick(schoolId)}>Add Student</Button> */}
                    </div>
                </div>
                <div className='employees-main'>
                    <div className='employees-content'>
                        <div className='search-options'>
                            <TextField
                                placeholder='Search'
                                type='search'
                                variant="outlined"
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className='pagination-options table-pagination'>
                            <div className='pagination-animation'></div>
                            <TablePagination
                                component="div"
                                count={filteredstudents?.length}
                                rowsPerPage={rowsPerPage}
                                page={page} // Adjust to be 0-based for MUI TablePagination
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={({ from, to, count }) => (
                                    `${from} to ${to} of ${count !== -1 ? count : `more than ${to}`}`
                                  )}
                            />
                            {/* <div className='filter'>
                                <IconButton
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick1}
                                >
                                    <FilterAltIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    className='filter-options'
                                >
                                    <div className='filter-menu'>
                                        <Typography variant='h6'>Filter schoollist</Typography>
                                        <CloseIcon onClick={handleClose1} />
                                    </div>
                                </Menu>
                            </div> */}
                        </div>
                    </div>
                    <div className='employee-table'>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <div className='employee-name'>
                                                S.NO
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name'>
                                                Name
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name'>
                                                Email
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name' >
                                                Mobile Number
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name' >
                                                Gender
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name' >
                                                Age
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name' >
                                                Birth Date
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name' >
                                                Age Limit
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name' >
                                                Mother Name
                                            </div>
                                        </TableCell>
                                        {/* <TableCell>
                                            <div className='employee-name' >
                                            Father Name
                                            </div>
                                        </TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedStudents.length > 0 ? (
                                        paginatedStudents.map((student, index) => (
                                            <TableRow key={student._id}>
                                                <TableCell component="th" scope="row">
                                                    {startIndex + index + 1}
                                                </TableCell>
                                                <TableCell align='left'>{student.name}</TableCell>
                                                <TableCell align="left">{student.email}</TableCell>
                                                <TableCell align="left">{student.mobile_no}</TableCell>
                                                <TableCell align="left">{student.Gender}</TableCell>
                                                <TableCell align="left">{student.age}</TableCell>
                                                <TableCell align="left">{student.date_of_birth}</TableCell>
                                                <TableCell align="left">{student.age_limit}</TableCell>
                                                <TableCell align="left">{student.mother_name}</TableCell>
                                                {/* <TableCell align="left">{student.father_name}</TableCell> */}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={12} align="center" >
                                                <Typography>No Data Found</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </section>
            )}
            <Addstudent openDialog={openDialog} setOpenDialog={setOpenDialog} setAddstudents={setAddstudents} schoolId={schoolId} />
        </React.Fragment>
    )
}

export default Student