import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  Button,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import instance from "../../utils/Api";
import Topbar from "../layouts/Topbar";

const Changepassword = () => {
  //handle show password
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword1, setShowPassword1] = useState(false);

  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };
  //usestate for changepassword
  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match");
      return;
    } else if (!newPassword && !confirmPassword) {
      toast.error("Passwords are required");
      return;
    } else if (!oldPassword && newPassword && confirmPassword) {
      toast.error("Old Password is required");
      return;
    }
    try {
        const token = localStorage.getItem('token')
      const response = await instance.patch("Admins/ResetPassword", {
        oldPassword,
        newPassword,
        confirmPassword,
      },{
        headers: {
            'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/admin/Dashboard");
      } else if (response.data.success === false) {
        // Handle the case when the response indicates an error
        toast.error(response.data.data.message);
      }
    } catch (error) {
      console.error("Error changing password:", error);
      toast.error(error.response.data.error);
    }
  };

  return (
    <>
      <div style={{ padding: "20px 20px 0px 0px" }}>
        <Topbar />
      </div>
      <div className="profile-back-btn-main">
        <div
          className="profile-back-btn"
          onClick={() => navigate("/admin/Dashboard")}
        >
          Back
        </div>
      </div>
      <section className="profile">
        <div className="profile-wrapper">
          <div className="profile-card">
            <div className="profile-text">
              <Typography variant="h4">Change Password</Typography>
            </div>
            <div className="profile-name">
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Old Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={oldPassword}
                      onChange={(e) => setoldPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Old Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password1">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password1"
                      type={showPassword1 ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setnewPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility1}
                            edge="end"
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="New Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password2">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password2"
                      type={showPassword2 ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setconfirmPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility2}
                            edge="end"
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                    />
                  </FormControl>
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleChangePassword}
                    className="change-btn"
                  >
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Changepassword;
