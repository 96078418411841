import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import '../../superadmin/styles/pages/dashboard.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import instance from '../../utils/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../Contex'; // Adjust the path


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Addschool = ({ setAddschools, openDialog, setOpenDialog, schools }) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [name, setName] = useState();
    const [mobileno, setMobileno] = useState();
    const [school, setSchool] = useState();
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [nameError, setNameError] = useState('');
    const [mobilenoError, setMobilenoError] = useState('');
    const [schoolError, setSchoolError] = useState('');
    const { buttondisable, setButtondisable } = useGlobalContext();
   
  

    useEffect(() => {
        if (schools) {
            setEmail(schools?.email || "")
            setName(schools?.name || "")
            setMobileno(schools?.mobile_no || "")
            setSchool(schools?.School || "")
        } else {
            setEmail("")
            setPassword("")
            setName("")
            setMobileno("")
            setSchool("")
        }
    }, [schools])

    //function popup close
    const handleClose = () => {
        setOpenDialog(false);
        setNameError('')
        setEmailError('')
        setMobilenoError('')
        setSchoolError('')
        setPasswordError('');
        
        //empty values
        if (schools) {
            setEmail(schools?.email || "")
            setName(schools?.name || "")
            setMobileno(schools?.mobile_no || "")
            setSchool(schools?.School || "")
        } else {
            setEmail("")
            setPassword("")
            setName("")
            setMobileno("")
            setSchool("")
        }
    };

    const validationform = () => {
        let isValid = true;

        // Name validation
        if (!name) {
            setNameError('Full Name is required');
            isValid = false;
        } else {
            setNameError('');
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.trim())) {
            setEmailError("Enter a valid email address");
            isValid = false
        } else {
            setEmailError("")
        }

        // Mobile number validation
        const mobileRegex = /^[0-9]\d{9}$/; // Regex to match only numbers
        if (!mobileno) {
            setMobilenoError('Mobile number is required');
            isValid = false;
        } else if (!mobileRegex.test(mobileno.toString().trim())) {
            setMobilenoError('Enter a valid 10-digit mobile number');
            isValid = false;
        } else {
            setMobilenoError("")
        }

        // School validation
        if (!school) {
            setSchoolError('School is required');
            isValid = false;
        } else {
            setSchoolError('');
        }

        // Password validation
        if (!password && !schools) {
            setPasswordError('Password is required');
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    }
    //add schools api call
    const handleAddSchool = async () => {
        if (!validationform()) {
            return;
        }
        setButtondisable(true);
        try {
            const response = await instance.post('Schools/Schoolsignup', {
                email,
                password,
                name,
                mobile_no: mobileno,
                School: school
            });
            if (response?.data?.success === true) {
                toast.success(response?.data?.message)
                handleClose();
                setAddschools(true);// Trigger refresh after create schools
                setButtondisable(false);
            } else {
                toast.error(response?.data?.message);
                setButtondisable(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.log(error.response);
            setButtondisable(false);
        }
    }

    //update schools api call
    const handleUpdateSchool = async () => {
        if (!email || !name || !mobileno ||!school) {
            toast.error('Please fill all the required fields');
            return;
        }
        if(!validationform()){
            return;
        }
        setButtondisable(true);
        try {
            const response = await instance.put(`Schools/updateSchool?user_id=${schools._id}`, {
                email,
                name,
                mobile_no: mobileno,
                School: school
            });
            if (response.data.success === true) {
                toast.success(response.data.message)
                handleClose();
                setAddschools(true); // Trigger refresh after update Admins
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            if (
                error?.response?.data?.error?.includes("E11000 duplicate key error collection: test.schools index: email_1")
              ) {
                toast.error(
                  `Email ${email} already exists.`
                );
              } else if(
                error?.response?.data?.error?.includes("E11000 duplicate key error collection: test.schools index: mobile_no_1")
              ) {
                toast.error(
                    `Mobile Number ${mobileno} already exists`
                )
              }
              else {
                toast.error(
                  error?.response?.data?.error || "An unexpected error occurred"
                );
              }
            setButtondisable(false);
        } 
    }

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                className="admin-admins"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {schools ? "Update" : "Add"} School
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <section>
                        <div className='add-admin'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the Full Name'
                                            label='Full Name'
                                            type='text'
                                            fullWidth
                                            required
                                            value={name}
                                            onChange={(e) => { setName(e.target.value); setNameError('') }}
                                            error={Boolean(nameError)}
                                        />
                                        <FormHelperText error={Boolean(nameError)}>
                                            {nameError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter Your Email'
                                            label='Email'
                                            type='email'
                                            fullWidth
                                            required
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value); setEmailError('') }}
                                            error={Boolean(emailError)}
                                        />
                                        <FormHelperText error={Boolean(emailError)}>
                                            {emailError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter mobile number'
                                            label='Mobile Number'
                                            type='number'
                                            fullWidth
                                            required
                                            value={mobileno}
                                            onChange={(e) => { setMobileno(e.target.value); setMobilenoError('') }}
                                            error={Boolean(mobilenoError)}
                                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 10)) }}
                                        />
                                        <FormHelperText error={Boolean(mobilenoError)}>
                                            {mobilenoError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter your School Name'
                                            label='School'
                                            type='text'
                                            fullWidth
                                            required
                                            value={school}
                                            onChange={(e) => { setSchool(e.target.value); setSchoolError('') }}
                                            error={Boolean(schoolError)}
                                        />
                                        <FormHelperText error={Boolean(schoolError)}>
                                            {schoolError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                {!schools ? (<Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter your Password'
                                            label='Password'
                                            type='password'
                                            fullWidth
                                            value={password}
                                            onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                                            required
                                            error={Boolean(passwordError)}
                                        />
                                        <FormHelperText error={Boolean(passwordError)}>
                                            {passwordError}
                                        </FormHelperText>
                                    </div>
                                </Grid>)
                                    : null}
                            </Grid>
                        </div>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="cancel-btn"
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        className="add-btn"
                        onClick={schools ? handleUpdateSchool : handleAddSchool}
                        disabled={buttondisable}
                        >
                        {schools ? "Update" : "Add"}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Addschool;

