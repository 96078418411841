import React, { useState } from "react";
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import "../../superadmin/styles/layouts/common_topbar.css";
import { Link, useNavigate } from "react-router-dom";

const Topbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlelogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/admin");
    window.location.reload();
  };

  return (
    <section className="topbar">
      <div className="topbar-main">
        <div>
          <Tooltip title="Account">
            <IconButton
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar />
            </IconButton>
          </Tooltip>
          <Menu
            className="menu-container"
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem>
              {" "}
              <Link className="link" to="/admin/changePassword">
                Reset Password{" "}
              </Link>
            </MenuItem>
            <MenuItem className="logout-btn" onClick={handlelogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    </section>
  );
};

export default Topbar;
