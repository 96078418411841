import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import '../../superadmin/styles/pages/dashboard.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import instance from '../../utils/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../../Contex'; // Adjust the path
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Addstudent = ({ setAddstudents, openDialog, setOpenDialog, schoolId }) => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [mobileno, setMobileno] = useState();
    const [gender, setGender] = useState();
    const [agelimit, setAgelimit] = useState();
    const [fathername, setFathername] = useState();
    const [mothername, setMothername] = useState();
    const [age, setAge] = useState();
    const [birthdate, setBirthdate] = useState(null);
    const [siblings, setSiblings] = useState();
    const [profilepic, setProfilepic] = useState();
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobilenoError, setMobilenoError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [agelimitError, setAgelimitError] = useState('');
    const [fathernameError, setFathernameError] = useState('');
    const [mothernameError, setMothernameError] = useState('');
    const [ageError, setAgeError] = useState('');
    const [birthdateError, setBirthdateError] = useState('');
    const { buttondisable, setButtondisable } = useGlobalContext();


    //function popup close
    const handleClose = () => {
        setOpenDialog(false);
        //empty usestates
        setName('');
        setEmail('');
        setMobileno('');
        setGender('');
        setAgelimit('');
        setFathername('');
        setMothername('');
        setAge('');
        setBirthdate(null)

        //empty validationerror
        setNameError('');
        setEmailError('');
        setMobilenoError('');
        setGenderError('');
        setAgelimitError('');
        setFathernameError('');
        setMothernameError('');
        setAgeError('');
        setBirthdateError('')
    };

    const validationform = () => {
        let isValid = true;

        // Name validation
        if (!name) {
            setNameError('Full Name is required');
            isValid = false;
        } else {
            setNameError('');
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            setEmailError("Email is required");
            isValid = false;
        } else if (!emailRegex.test(email.trim())) {
            setEmailError("Enter a valid email address");
            isValid = false;
        } else {
            setEmailError(""); // Clear the error if email is valid
        }


        // Mobile number validation
        const mobileRegex = /^[6-9]\d{9}$/; // Regex to match only numbers
        if (!mobileno) {
            setMobilenoError('Mobile number is required');
            isValid = false;
        } else if (!mobileRegex.test(mobileno.toString().trim())) {
            setMobilenoError('Enter a valid 10-digit mobile number');
            isValid = false;
        } else {
            setMobilenoError("")
        }

        // Gender validation
        if (!gender) {
            setGenderError("Please select a gender");
            isValid = false;
        } else {
            setGenderError("");
        }

        // Age limit validation
        if (!agelimit) {
            setAgelimitError("Please select an age limit");
            isValid = false;
        } else {
            setAgelimitError("");
        }

        // Father Name validation
        if (!fathername) {
            setFathernameError("Father Name is required");
            isValid = false;
        } else {
            setFathernameError("");
        }

        // Mother Name validation
        if (!mothername) {
            setMothernameError("Mother Name is required");
            isValid = false;
        } else {
            setMothernameError("");
        }

        // Age validation
        if (!age) {
            setAgeError("Age is required ");
            isValid = false;
        } else if (age > 99) {
            setAgeError("Age cannot exceed 99");
            isValid = false;
        } else {
            setAgeError("");
        }

        // Birthdate validation
        if (!birthdate) {
            setBirthdateError("Please select a birth date.");
            isValid = false;
        } else if (dayjs(birthdate).isAfter(dayjs())) {
            setBirthdateError("Birth date cannot be in the future.");
            isValid = false;
        } else {
            setBirthdateError("");
        }

        return isValid;
    }



    //add sctudents api call
    const handleAddStudents = async () => {
        if (!validationform()) {
            return;
        }
        setButtondisable(true);
        try {
            const formData = new FormData();
            formData.append('school_id', schoolId);
            formData.append('name', name);
            formData.append('email', email);
            formData.append('mobile_no', mobileno);
            formData.append('Gender', gender);
            formData.append('age_limit', agelimit);
            formData.append('father_name', fathername);
            formData.append('mother_name', mothername);
            formData.append('age', age);
            formData.append('date_of_birth', birthdate ? dayjs(birthdate).format('DD/MM/YYYY') : '');
            formData.append('siblings', siblings);
            formData.append('profile_pic', profilepic);

            const response = await instance.post('Students/Studentsignup', formData);
            if (response.data.success === true) {
                toast.success(response.data.message)
                handleClose();
                setAddstudents(true);// Trigger refresh after create schools
                setButtondisable(false);
            } else {
                toast.error(response.data.message);
                setButtondisable(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.log(error.response);
            setButtondisable(false);
        }
    }



    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                className="admin-admins"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Student
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <section>
                        <div className='add-admin'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the Full Name'
                                            label='Full Name'
                                            type='text'
                                            fullWidth
                                            required
                                            value={name}
                                            onChange={(e) => { setName(e.target.value); setNameError('') }}
                                            error={Boolean(nameError)}
                                        />
                                        <FormHelperText error={Boolean(nameError)}>
                                            {nameError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter Your Email'
                                            label='Email'
                                            type='email'
                                            fullWidth
                                            required
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value); setEmailError('') }}
                                            error={Boolean(emailError)}
                                        />
                                        <FormHelperText error={Boolean(emailError)}>
                                            {emailError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter mobile number'
                                            label='Mobile Number'
                                            type='number'
                                            fullWidth
                                            required
                                            value={mobileno}
                                            onChange={(e) => { setMobileno(e.target.value); setMobilenoError('') }}
                                            error={Boolean(mobilenoError)}
                                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 10)) }}
                                        />
                                        <FormHelperText error={Boolean(mobilenoError)}>
                                            {mobilenoError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <FormControl fullWidth error={Boolean(genderError)} required>
                                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={gender}
                                                label="Gender"
                                                onChange={(e) => { setGender(e.target.value); setGenderError('') }}
                                            >
                                                <MenuItem value="Male">Male</MenuItem>
                                                <MenuItem value="Female">Female</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormHelperText error={Boolean(genderError)}>
                                            {genderError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <FormControl fullWidth error={Boolean(agelimitError)} required>
                                            <InputLabel id="demo-simple-select-label">Age Limit</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={agelimit}
                                                label="Gender"
                                                onChange={(e) => { setAgelimit(e.target.value); setAgelimitError('') }}
                                            >
                                                <MenuItem value="7 months to 3 years">7 months to 3 years</MenuItem>
                                                <MenuItem value="3 to 6 years">3 to 6 years</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormHelperText error={Boolean(agelimitError)}>
                                            {agelimitError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the Father Name'
                                            label='Father Name'
                                            type='text'
                                            fullWidth
                                            required
                                            value={fathername}
                                            onChange={(e) => { setFathername(e.target.value); setFathernameError('') }}
                                            error={Boolean(fathernameError)}
                                        />
                                        <FormHelperText error={Boolean(fathernameError)}>
                                            {fathernameError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the Mother Name'
                                            label='Mother Name'
                                            type='text'
                                            fullWidth
                                            required
                                            value={mothername}
                                            onChange={(e) => { setMothername(e.target.value); setMothernameError('') }}
                                            error={Boolean(mothernameError)}
                                        />
                                        <FormHelperText error={Boolean(mothernameError)}>
                                            {mothernameError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the Age'
                                            label='Age'
                                            type='number'
                                            fullWidth
                                            required
                                            value={age}
                                            onChange={(e) => { setAge(e.target.value); setAgeError('') }}
                                            error={Boolean(ageError)}
                                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 2)) }}
                                        />
                                        <FormHelperText error={Boolean(ageError)}>
                                            {ageError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='student-textfiled'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DatePicker
                                                label="Birth Date"
                                                value={birthdate} // Use value prop with the current date
                                                onChange={(newDate) => { setBirthdate(newDate); setBirthdateError('') }}
                                                format="DD/MM/YYYY"
                                                required
                                            />
                                        </LocalizationProvider>
                                        <FormHelperText error={Boolean(birthdateError)}>
                                            {birthdateError}
                                        </FormHelperText>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='admin-textfiled'>
                                        <TextField
                                            placeholder='Enter the Siblings'
                                            label='Siblings'
                                            type='text'
                                            fullWidth
                                            value={siblings}
                                            onChange={(e) => setSiblings(e.target.value)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className="admin-textfiled">
                                        <TextField
                                            id="outlined-basic"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => setProfilepic(e.target.files[0])}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="cancel-btn"
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        className="add-btn"
                        onClick={handleAddStudents}
                        disabled={buttondisable}
                    >
                        Add
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Addstudent;

