import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Menu,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Topbar from "../../admin/layouts/Topbar";
import Addproduct from "../../admin/components/Add_product";
import CircularProgress from "@mui/material/CircularProgress";
import instance from "../../utils/Api";
import Statusproduct from "../../admin/components/Status_product";
import { useGlobalContext } from "../../Contex"; // Adjust the path
import edit from '../../superadmin/assets/icons/add-icon.svg';

const Products = () => {
  const { openDialog, setOpenDialog } = useGlobalContext();
  const { openStatusDialog, setOpenStatusDialog } = useGlobalContext(); //status popup
  const { loading, setLoading } = useGlobalContext(); // State to handle loading
  const { anchorEl, setAnchorEl } = useGlobalContext(); //filter
  const { searchTerm, setSearchTerm } = useGlobalContext(); //search state
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Fixed rows per page
  const startIndex = page * rowsPerPage; // Calculate the start and end index for pagination
  const [products, setProducts] = useState([]);
  const [addproducts, setAddproducts] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Handle search
  const filteredProducts = products.filter((product) => {
    const searchTermLower = searchTerm.toLowerCase(); // Ensure searchTerm is always a string
    return (
      product?.product_name?.toLowerCase().includes(searchTermLower) ||
      product?.unit?.toLowerCase().includes(searchTermLower)
      // product?.status?.toLowerCase().includes(searchTermLower)
      // product?.age_limit?.toString().includes(searchTermLower)||
    );
  });

  //handle change
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Adjust to be 1-based
  };

  // Data slicing based on current page and rowsPerPage
  const paginatedproducts = filteredProducts.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  //filter function open menu
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //filter function close menu
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  //fetch products
  const fetchProducts = async () => {
    try {
      const response = await instance.get("Inventory/getProducts");
      setProducts(response.data.data);
    } catch (error) {
      console.error("Error fetching Products:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    setAddproducts(false); // Reset after fetching
    setLoading(true);
  }, [addproducts]);

  // Function to handle opening the popup for adding new admin
  const handleAddClick = () => {
    setSelectedProduct(null); // Clear selected admin (add new mode)
    setOpenDialog(true); // Open the popup
  };
  
  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setOpenDialog(true);
  }

  // Function to handle status click and open the popup
  const handleStatusClick = (product) => {
    setSelectedProduct(product); // Store the selected product
    setOpenStatusDialog(true); // Open the popup
  };

  return (
    <React.Fragment>
      <Topbar />
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <section className="main">
          <div className="employees-text">
            <Typography variant="h5">Products</Typography>
            <Button variant="outlined" onClick={handleAddClick}>
              Add Product
            </Button>
          </div>
          <div className="employees-main">
            <div className="employees-content ">
              <div className="search-options">
                <TextField
                  placeholder="Search"
                  type="search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setPage(0);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="pagination-options table-pagination">
                <div className="pagination-animation"></div>
                <TablePagination
                  component="div"
                  count={filteredProducts?.length}
                  rowsPerPage={rowsPerPage}
                  page={page} // Adjust to be 0-based for MUI TablePagination
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from} to ${to} of ${
                      count !== -1 ? count : `more than ${to}`
                    }`
                  }
                />
                {/* <div className='filter'>
                                <IconButton
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick1}
                                >
                                    <FilterAltIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    className='filter-options'
                                >
                                    <div className='filter-menu'>
                                        <Typography variant='h6'>Filter Products</Typography>
                                        <CloseIcon onClick={handleClose1} />
                                    </div>
                                </Menu>
                            </div> */}
              </div>
            </div>
            <div className="employee-table">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="employee-name">S.NO</div>
                      </TableCell>
                      <TableCell>
                        <div className="employee-name">Product Name</div>
                      </TableCell>
                      <TableCell>
                        <div className="employee-name">Unit</div>
                      </TableCell>

                      <TableCell>
                        <div className="employee-name">Age Limit</div>
                      </TableCell>
                      <TableCell>
                        <div className="employee-name">Action</div>
                      </TableCell>
                      <TableCell>
                        <div className="employee-name">Status</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedproducts.length > 0 ? (
                      paginatedproducts.map((product, index) => (
                        <TableRow abIndex={-1} key={product._id}>
                          <TableCell component="th" scope="row">
                            {startIndex + index + 1}
                          </TableCell>
                          <TableCell align="left">
                            {product.product_name}
                          </TableCell>
                          <TableCell align="left">{product.unit}</TableCell>
                          <TableCell align="left">
                            {product.age_limits
                              ? product.age_limits.join(", ")
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                            <span
                              className="view"
                              onClick={() => handleEditClick(product)}
                            >
                              <Tooltip title="Update Product">
                                <img src={edit} alt="edit" />
                              </Tooltip>
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() => {
                              handleStatusClick(product);
                            }}
                          >
                            <Tooltip title="Change Status">
                              <span
                                style={{
                                  backgroundColor:
                                    product.status === true
                                      ? "rgb(229, 252, 240)"
                                      : "rgb(255, 232, 232)",
                                  color:
                                    product.status === true
                                      ? "rgb(0, 166, 81)"
                                      : "rgb(235, 87, 87)",
                                  padding: "5px 7px",
                                  borderRadius: "3px",
                                  cursor: "pointer",
                                }}
                              >
                                {product.status === true
                                  ? "Active"
                                  : "Inactive"}
                              </span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      )}
      <Addproduct
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setAddproducts={setAddproducts}
        selectedProduct={selectedProduct}
        onProductUpdate={fetchProducts}
      />
      <Statusproduct
        open={openStatusDialog}
        handleClose={() => setOpenStatusDialog(false)}
        product={selectedProduct}
        setAddproducts={setAddproducts}
      />
    </React.Fragment>
  );
};

export default Products;
