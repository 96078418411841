import React, { useState, useEffect } from 'react';
import Topbar from '../../admin/layouts/Topbar';
import '../../superadmin/styles/pages/dashboard.css';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, InputAdornment, IconButton, Menu, Typography,
  Button,
  Tooltip,
} from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import view from '../../superadmin/assets/icons/view-icon.svg';
import edit from '../../superadmin/assets/icons/add-icon.svg';
import Addschool from '../../admin/components/Add_school';
import CircularProgress from '@mui/material/CircularProgress';
import instance from '../../utils/Api';
import Statusschool from '../../admin/components/Status_school';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../Contex'; // Adjust the path


const Schoollist = () => {
  const { openDialog, setOpenDialog } = useGlobalContext();
  const { openStatusDialog, setOpenStatusDialog } = useGlobalContext();//status popup
  const { loading, setLoading } = useGlobalContext();  // State to handle loading
  const { anchorEl, setAnchorEl } = useGlobalContext();   //filter
  const { searchTerm, setSearchTerm } = useGlobalContext(); //search state
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Fixed rows per page
  const startIndex = (page) * rowsPerPage;  // Calculate the start and end index for pagination
  const [schools, setSchools] = useState([]);
  const [addschools, setAddschools] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);  // To store the selected admin for editing
  const navigate = useNavigate();
  const [studentCounts, setStudentCounts] = useState({});

    // //filter function open menu
    // const handleClick1 = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
    // //filter function close menu
    // const handleClose1 = () => {
    //   setAnchorEl(null);
    // };

  // Handle search
  const filteredSchools = schools.filter((school) => {
    const searchTermLower = (searchTerm).toLowerCase(); // Ensure searchTerm is always a string
    return (
      school?.name?.toLowerCase().includes(searchTermLower) ||
      school?.School?.toLowerCase().includes(searchTermLower) ||
      school?.email?.toLowerCase().includes(searchTermLower) ||
      school?.mobile_no?.toString().includes(searchTermLower)
    );
  });

  //handle change
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Adjust to be 1-based
  };

  // Data slicing based on current page and rowsPerPage
  const paginatedSchools = filteredSchools.slice(page * rowsPerPage, (page + 1) * rowsPerPage);


  //fetch fetchSchools
  const fetchSchools = async () => {
    try {
      const { data: { data: schoolList } } = await instance.get('Schools/getSchools');
      setSchools(schoolList);  // Store the schools list

      // Fetch student count for each school
      const counts = await Promise.all(
        schoolList.map(async (school) => {
          const count = await fetchStudentCount(school._id);
          return { schoolId: school._id, count };
        })
      );

      // Store student counts in a dictionary format by school ID
      const countsById = counts.reduce((acc, { schoolId, count }) => {
        acc[schoolId] = count;
        return acc;
      }, {});

      setStudentCounts(countsById);  // Set the counts to state
    } catch (error) {
      console.error('Error fetching Schools:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchools();
    setAddschools(false) // Reset after fetching
    setLoading(true);
  }, [addschools]);

 
  // Fetch student count for a school
  const fetchStudentCount = async (school_id) => {
    try {
      const response = await instance.get(`Students/StudentsList?school_id=${school_id}`);
      return response.data.data.length; // Assuming you get an array of students
    } catch (error) {
      console.error(`Error fetching student count for school ${school_id}:`, error.message);
      return 0; // Return 0 if there's an error
    }
  };
  
  // Function to handle opening the popup for editing
  const handleEditClick = (school) => {
    setSelectedSchool(school);  // Set the selected admin
    setOpenDialog(true);  // Open the popup
  };

  // Function to handle opening the popup for adding new admin
  const handleAddClick = () => {
    setSelectedSchool(null);  // Clear selected admin (add new mode)
    setOpenDialog(true);  // Open the popup
  };

  //Function to handle opening the popup status 
  const handleStatusClick = (school) => {
    setSelectedSchool(school);
    setOpenStatusDialog(true);
  };



  return (
    <React.Fragment>
      <Topbar />
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (<section className='main'>
        <div className='employees-text'>
          <Typography variant='h5'>School List</Typography>
          <Button variant="outlined" onClick={handleAddClick}>Add School</Button>
        </div>
        <div className='employees-main'>
          <div className='employees-content'>
            <div className='search-options'>
              <TextField
                placeholder='Search'
                type='search'
                variant="outlined"
                value={searchTerm}
                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className='pagination-options table-pagination'>
              <div className='pagination-animation'></div>
              <TablePagination
                component="div"
                count={filteredSchools?.length}
                rowsPerPage={rowsPerPage}
                page={page} // Adjust to be 0-based for MUI TablePagination
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ from, to, count }) => (
                  `${from} to ${to} of ${count !== -1 ? count : `more than ${to}`}`
                )}
              />
              {/* <div className='filter'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick1}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose1}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='filter-options'
                >
                  <div className='filter-menu'>
                    <Typography variant='h6'>Filter schoollist</Typography>
                    <CloseIcon onClick={handleClose1} />
                  </div>
                </Menu>
              </div> */}
            </div>
          </div>
          <div className='employee-table'>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className='employee-name'>
                        S.NO
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name'>
                        Name
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name'>
                        School Name
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name' >
                        Email
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name' >
                        Mobile Number
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name' >
                        Status
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name-1' >
                        Action
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name-1' >
                        Student
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name-1' >
                        Inventory
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className='employee-name-1' >
                        Transaction
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedSchools.length > 0 ? (
                    paginatedSchools.map((school, index) => (
                      <TableRow tabIndex={-1} key={school._id}>
                        <TableCell component="th" scope="row">
                          {startIndex + index + 1}
                        </TableCell>
                        <TableCell align='left'>{school.name}</TableCell>
                        <TableCell align="left">{school?.School}</TableCell>
                        <TableCell align="left">{school?.email}</TableCell>
                        <TableCell align="left">{school.mobile_no}</TableCell>
                        <TableCell align='left'><span onClick={() => handleStatusClick(school)} style={{ backgroundColor: school.status === true ? 'rgb(229, 252, 240)' : 'rgb(255, 232, 232)', color: school.status === true ? 'rgb(0, 166, 81)' : 'rgb(235, 87, 87)', padding: "5px 7px", borderRadius: "3px", cursor: "pointer" }}><Tooltip title="Change Status" >{school.status === true ? "Active" : "Inactive"}</Tooltip></span> </TableCell>
                        <TableCell align="center">
                          <span  className='view' onClick={() => handleEditClick(school)}>
                          <Tooltip title="Update School"><img src={edit} alt="edit" /></Tooltip>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className='view' onClick={() => navigate(`/admin/student?schoolId=${school._id}&schoolname=${school.School}`)}>
                         <img src={view} alt="view" />
                         <strong>{studentCounts[school._id] || 0}</strong>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className='view' onClick={() => navigate(`/admin/inventory?schoolId=${school._id}&schoolname=${school.School}`)}>
                          <Tooltip title="Inventory"><img src={view} alt="view" /></Tooltip>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className='view' onClick={() => navigate(`/admin/transaction?schoolId=${school._id}&schoolname=${school.School}`)}>
                          <Tooltip title="Transaction"><img src={view} alt="view" /></Tooltip>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                  ) :
                    (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </section>
      )}
      <Addschool openDialog={openDialog} setOpenDialog={setOpenDialog} setAddschools={setAddschools} schools={selectedSchool} />
      <Statusschool open={openStatusDialog} handleClose={() => setOpenStatusDialog(false)} schools={selectedSchool} setAddschools={setAddschools} />
    </React.Fragment>
  )
}

export default Schoollist